import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';

const userColors = {};

function assignColor(userId) {
  const colors = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];
  if (!userColors[userId]) {
    userColors[userId] = colors[Object.keys(userColors).length % colors.length];
  }
  return userColors[userId];
}

const WorkTimeline = () => {
    const [items, setItems] = useState([]);
    const [groups, setGroups] = useState([]);
    const [defaultTimeStart, setDefaultTimeStart] = useState(moment().startOf('week'));
    const [defaultTimeEnd, setDefaultTimeEnd] = useState(moment().startOf('week').add(1, 'week'));

    const apiURL = process.env.REACT_APP_API_BASE_URL || '';

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const { data } = await axios.get(`${apiURL}/api/logs/work-schedule`);
                const groupsData = data.map(user => ({
                    id: user.id,
                    title: user.name,
                }));
                const itemsData = data.map(user => ({
                    id: `${user.id}-${user.start_time}`,
                    group: user.id,
                    title: 'Work Period',
                    start_time: user.start_time ? moment(user.start_time) : moment(),
                    end_time: user.end_time ? moment(user.end_time) : moment(),
                    itemProps: {
                        style: {
                            background: assignColor(user.id), // Ensure this function handles undefined or null
                            color: 'white',
                        }
                    }
                }));                
                setGroups(groupsData);
                setItems(itemsData);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };

        fetchEvents();
    }, []);

    return (
        <div className="container mx-auto px-4 py-2">
            <h1 className="text-2xl font-bold text-center mb-6">Weekly Work Schedule</h1>
            <div className="bg-white shadow-xl rounded-lg p-4">
            </div>
        </div>
    );
};

export default WorkTimeline;